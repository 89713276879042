<template>
  실패실패실패실패
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'regist-fail-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
