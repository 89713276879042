export const componentState = () => {
  const init = async () => {
    alert('본인인증에 실패하였습니다.')
    window.self.close()
  }

  init()

  return { init }
}
